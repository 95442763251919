@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */
@layer utilities {
    .rtl {
        direction: rtl;
    }
}

/* styles/globals.css */

html {
    scroll-behavior: smooth;
}

.masonry-grid {
    display: flex;
    flex-wrap: wrap;
}

.masonry-column {
    box-sizing: border-box;
}